import React from 'react';
import {Box, HStack, Icon, Link, Progress, Text, VStack} from '@chakra-ui/react';
import {useQuery} from '@tanstack/react-query';
import {MdCheckCircle, MdOutlineCircle} from 'react-icons/md';
import {Link as ReactLink} from 'react-router-dom';
import {Puddleglum} from '../../../puddleglum';

const EmployerOnboarding = () => {
	const {data: onboardingStatus} = useQuery(['employer-onboarding'], async () => {
		const reply =
			await Puddleglum.Controllers.Employer.EmployerOnboardingController.getStatus();
		return reply.data;
	});

	const isEverythingCompleted =
		onboardingStatus?.is_profile_completed && onboardingStatus?.are_employees_added;

	if (!onboardingStatus) return <Progress size='sm' isIndeterminate />;

	if (isEverythingCompleted)
		return (
			<Box mt={4}>
				<HStack>
					<Icon as={MdCheckCircle} color='green.500' boxSize={4} />
					<Text>You have completed all the onboarding steps!</Text>
				</HStack>
			</Box>
		);

	return (
		<VStack mt={4}>
			<HStack w='full' alignContent='start'>
				<Icon
					as={onboardingStatus.is_profile_completed ? MdCheckCircle : MdOutlineCircle}
					color={onboardingStatus.is_profile_completed ? 'green.500' : 'red.500'}
				/>
				<Link as={ReactLink} to='/settings' flex={1} p={0}>
					Complete Your Organization Profile
				</Link>
			</HStack>
			<HStack w='full' alignContent='start'>
				<Icon
					as={onboardingStatus.are_employees_added ? MdCheckCircle : MdOutlineCircle}
					color={onboardingStatus.are_employees_added ? 'green.500' : 'red.500'}
				/>
				<Link as={ReactLink} to='/locations' flex={1} p={0}>
					Add Your Employees
				</Link>
			</HStack>
		</VStack>
	);
};

export default EmployerOnboarding;
